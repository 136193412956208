import React from "react";
import {wrapContent} from "../components/content";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {
    bodyText,
    heading2,
    heading4,
    mobileBodyText,
    mobileHeading3,
    mobileHeading4,
    getContainerStyle
} from "../components/styles";


const getHeading2Style = (breakpoints) => {
    const baseStyle = breakpoints.sm ? mobileHeading3 : heading2
    return {
        ...baseStyle,
        fontWeight: "900",
        marginBottom: breakpoints.sm ? "24px" : "48px"
    }
}

const getHeading4Style = (breakpoints) => {
    const baseStyle = breakpoints.sm ? mobileHeading4 : heading4
    return {
        ...baseStyle,
        fontWeight: "bold",
        textDecoration: "underline",
    }
}

const getCaptionStyle = (breakpoints) => {
    const baseStyle = breakpoints.sm ? mobileBodyText : bodyText;
    return {
        ...baseStyle,
        fontStyle: "oblique",
        marginBottom: "6px"
    }
}

const getBodyStyle = (breakpoints) => {
    const baseStyle = breakpoints.sm ? mobileBodyText : bodyText;
    return {
        ...baseStyle,
        marginBottom: breakpoints.sm ? "12px" : "30px"
    }
}

const getBoxStyle = (breakpoints) => {
   return {
       ...getContainerStyle(breakpoints),
       paddingBottom: breakpoints.sm ? "12px" : "24px",
       height: "auto",
       maxHeight: "auto"
   }
}

const TeachingPage = () => {
    const breakpoints = useBreakpoint()
    return (
        <div style={getBoxStyle(breakpoints)}>
            <h2 style={getHeading2Style(breakpoints)}>teaching </h2>
            <h4 style={getHeading4Style(breakpoints)}> Teaching Fellow: Yale ECON501, General Economic Theory: Microeconomics</h4>
            <p style={getCaptionStyle(breakpoints)}> Instructor: Dirk Bergemann</p>
            <p style={getBodyStyle(breakpoints)}> Spring 2024 </p>
            <h4 style={getHeading4Style(breakpoints)}> Teaching Assistant: MIT 6.046, Design and Analysis of Algorithms </h4>
            <p style={getCaptionStyle(breakpoints)}> Instructors: Konstantinos Daskalakis, Piotr Indyk, Mauricio Karchmer</p>
            <p style={getBodyStyle(breakpoints)}> Fall 2019 </p>
            <h4 style={getHeading4Style(breakpoints)}> Teaching Assistant: MIT 6.046, Design and Analysis of Algorithms </h4>
            <p style={getCaptionStyle(breakpoints)}> Instructors: Mauricio Karchmer, Aleksander Madry, Bruce Tidor</p>
            <p style={getBodyStyle(breakpoints)}> Spring 2019 </p>
            <h4 style={getHeading4Style(breakpoints)}> Teaching Assistant: MIT 6.046, Design and Analysis of Algorithms </h4>
            <p style={getCaptionStyle(breakpoints)}> Instructors: Konstantinos Daskalakis, Srinivas Devadas, Debayan Gupta, Ronitt Rubinfeld </p>
            <p style={getBodyStyle(breakpoints)}> Fall 2018 </p>
            <h4 style={getHeading4Style(breakpoints)}> Teaching Assistant: MIT 6.857, Computer and Network Security </h4>
            <p style={getCaptionStyle(breakpoints)}> Instructors: Yael Kalai, Ron Rivest</p>
            <p style={getBodyStyle(breakpoints)}> Spring 2018 </p>
        </div>
    )
}

export default wrapContent(TeachingPage)